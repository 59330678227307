<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'

import MenuComponent from '@/components/common/MenuComponent.vue'
import TitleComponent from '@/components/common/TitleComponent.vue'
import EasyDataTable from 'vue3-easy-data-table'

import type { ClickRowArgument, Header, Item, ServerOptions } from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'
import i18next from 'i18next'
import { DateTime } from 'luxon'
import router from '@/router'
import IconFlag from '@/components/icons/IconFlag.vue'

const t = i18next.t

const showFilters = ref(false)
const communities = ref(["01", "ar", "ba", "br", "cz", "de", "dk", "en", "es", "fi", "fr", "gr", "hr", "hu", "it", "jp", "mx", "nl", "no", "pl", "pt", "ro", "ru", "se", "si", "sk", "tr", "tw", "us"])
const filters = ref({
    community: "01",
    player: ""
})

const dataTable = ref()
const loading = ref(false)
const headers = ref<Header[]>([
    { text: t('reports.community'), value: "community", sortable: true },
    { text: t('reports.header.universe'), value: "server", sortable: true },
    { text: t('reports.header.attacker.title'), value: "attackers" },
    { text: t('reports.header.defender.title'), value: "defenders" },
    { text: t('reports.header.attacker.summary'), value: "attacker_sum", sortable: true },
    { text: t('reports.header.defender.summary'), value: "defender_sum", sortable: true },
    { text: t('reports.header.date'), value: "time", sortable: true }
])

const totalItems = ref(0)
const items = ref<Item[]>([])

const serverOptions = ref<ServerOptions>({
    page: 1,
    rowsPerPage: 25,
    sortBy: "time",
    sortType: "desc"
})

function toggleFilters() {
    showFilters.value = !showFilters.value
}

async function loadReports() {
    loading.value = true
    console.log('loadreports')
    console.log(serverOptions.value)
    console.log(dataTable.value)
    // console.log(dataTable.value.nextPage())
    await fetch(`${import.meta.env.VITE_OGOTCHA_API}/v1/reports`,{
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({options: serverOptions.value, filters: filters.value})
    }).then(data => data.json())
        .then(data => {
            items.value = data.results
            totalItems.value = data.rows
        })
        .catch(err => {
            console.error(err)
        })

    loading.value = false
    console.log(serverOptions.value)
}

function prettyNames(str: string): string {
    console.log(str)
    const names = str.split(';')

    return names.join(', ')
}

function showReport(item: Item & ClickRowArgument) {
    router.push(`/${i18next.language}/report/${item.id}`)
}

onMounted(loadReports)
watch(serverOptions, () => { loadReports() }, { deep: true })

</script>

<template>
    <div id="page-reports">
        <TitleComponent />
        <MenuComponent />
        <div id="reports">
            <h2 class="subtitle">{{ $t('reports.title') }}</h2>
            <div id="reports-table-wrapper" class="clearfix">
                <div id="reports-filters-wrapper" v-bind:class="{'active': showFilters }">
                    <h3 @click="toggleFilters">{{ $t('reports.filters.title') }}</h3>
                    <div id="reports-filters" class="clearfix">
                        <div class="reports-filter" id="report-filter-player">
                            <label>
                                {{ $t('reports.filters.player') }}
                                <input type="text" placeholder="Warsaalk" v-model="filters.player" @submit="loadReports" />
                            </label>
                            <span class="aligner">&nbsp;</span>
                        </div>
                        <div class="reports-filter" id="reports-filter-community">
                            {{ $t('reports.community') }}
                            <div id="filter-community-wrapper">
                                <div id="filter-community"><IconFlag :code="filters.community" /></div>
                                <ul>
                                    <li
                                        v-for="community in communities"
                                        :key="community" @click="filters.community = community"
                                    >
                                        <IconFlag :code="community" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="reports-filter clearfix">
                            <div id="reports-filters-reset">{{ $t('reports.filters.reset') }}</div>
                            <div id="reports-filters-reset" @click="loadReports">{{ $t('reports.filters.apply') }}</div>
                            <span class="aligner">&nbsp;</span>
                        </div>
                    </div>
                </div>
                <EasyDataTable id="reports-table"
                    v-model:server-options="serverOptions"
                    :server-items-length="totalItems"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :prevent-context-menu-row="false"
                    :hideRowsPerPage=true
                    header-text-direction="center"
                    body-text-direction="center"
                    @click-row="showReport"
                >
                    <template #item-community="{ community }">
                        <IconFlag :code="community" />
                    </template>
                    <template #item-attackers="{ attackers }">
                        {{ prettyNames(attackers) }}
                    </template>
                    <template #item-defenders="{ defenders }">
                        {{ prettyNames(defenders) }}
                    </template>
                    <template #item-attacker_sum="{ attacker_sum }">
                        <span v-bind:class="{'negative': attacker_sum < 0, 'positive': attacker_sum > 0 }">
                            {{ new Intl.NumberFormat('nl').format(attacker_sum) }}
                        </span>
                    </template>
                    <template #item-defender_sum="{ defender_sum }">
                        <span v-bind:class="{'negative': defender_sum < 0, 'positive': defender_sum > 0 }">
                            {{ new Intl.NumberFormat('nl').format(defender_sum) }}
                        </span>
                    </template>
                    <template #item-time="{ time }">
                        {{ DateTime.fromISO(time).toFormat('dd-LL-yyyy') }} --:--:--
                    </template>
                </EasyDataTable>
            </div>
        </div>
    </div>
</template>

<style>

#reports-table table {
    border-spacing: 0 !important;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin: 15px 0;
    clear: both;
}

/* Reports Table */
#reports #reports-table-wrapper { padding: 2%; border-radius: 3px; background: #1E2B39; } 
#reports #reports-table { border-spacing: 0; width: 100%; box-sizing: border-box; text-align: center; margin: 15px 0; clear: both; }
#reports #reports-table th { padding: 0.5%; border: 1px solid #151F29; border-bottom: 0; background: #151F29; }
#reports #reports-table th[data-sort]:hover { background: #06090E; cursor: pointer; }
#reports #reports-table th:first-child { border-top-left-radius: 3px; }
#reports #reports-table th:last-child { border-top-right-radius: 3px; }
#reports #reports-table[data-column="universe"] th[data-sort="universe"],
#reports #reports-table[data-column="community"] th[data-sort="community"],
#reports #reports-table[data-column="attacker"] th[data-sort="attacker"],
#reports #reports-table[data-column="defender"] th[data-sort="defender"],
#reports #reports-table[data-column="time"] th[data-sort="time"] { background: #06090E; padding-right: 23px; position: relative; }
#reports #reports-table[data-column="universe"] th[data-sort="universe"]:after,
#reports #reports-table[data-column="community"] th[data-sort="community"]:after,
#reports #reports-table[data-column="attacker"] th[data-sort="attacker"]:after,
#reports #reports-table[data-column="defender"] th[data-sort="defender"]:after,
#reports #reports-table[data-column="time"] th[data-sort="time"]:after { background-image: url('@/assets/arrow-sprites.png'); background-repeat: no-repeat; background-position: 0 -13px; width: 13px; height: 13px; display: block; content: " "; position: absolute; right: 5px; top: 9px; }
#reports #reports-table[data-order="asc"] th[data-sort="universe"]:after,
#reports #reports-table[data-order="asc"] th[data-sort="community"]:after,
#reports #reports-table[data-order="asc"] th[data-sort="attacker"]:after,
#reports #reports-table[data-order="asc"] th[data-sort="defender"]:after,
#reports #reports-table[data-order="asc"] th[data-sort="time"]:after { background-position: 0 0; }

#reports #reports-table td { background: #39495e; padding: 0.5%; border: solid #151F29; border-width: 1px 0 0 1px; }
#reports #reports-table td.negative { color: rgb(255, 100, 100); }
#reports #reports-table td.positive { color: rgb(63, 255, 63); }
#reports #reports-table tr td:last-child { border-right-width: 1px; }
#reports #reports-table tr:last-child td { border-bottom-width: 1px; }
#reports #reports-table tr:last-child td:first-child { border-bottom-left-radius: 3px; }
#reports #reports-table tr:last-child td:last-child { border-bottom-right-radius: 3px; }
#reports #reports-table tr:hover td { background: #1E2B39; cursor: pointer; }

/* Reports Paging */
/* #reports #reports-paging { float: right; }
#reports #reports-paging-numbers { display: inline-block; }
#reports #reports-paging .paging-btn { background: #151F29; display: inline-block; padding: 5px; border-radius: 2px; cursor: pointer; margin-left: 3px; min-width: 13px; text-align: center; vertical-align: middle; }
#reports #reports-paging .paging-btn.paging-current,
#reports #reports-paging .paging-btn:hover { background: #06090E; }
#reports #reports-paging .paging-btn-default:after { background: url(@/assets/arrow-sprites.png); background-repeat: no-repeat; width: 13px; height: 13px; display: block; content: " "; }
#reports #reports-paging #paging-first:after { background-position: -26px 0; }
#reports #reports-paging #paging-prev:after { background-position: -13px 0; }
#reports #reports-paging #paging-next:after { background-position: -13px -13px; }
#reports #reports-paging #paging-last:after { background-position: -26px -13px; }
#reports #reports-paging-numbers .paging-btn { padding: 4px 5px; } */

.negative { color: rgb(255, 100, 100) }
.positive { color: rgb(63, 255, 63) }

#reports-table {
  --easy-table-border: 0;
  --easy-table-row-border: 1px solid #445269;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #fff;
  --easy-table-header-background-color: #2d3a4f;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;

  --easy-table-body-row-font-color: #fff;
  --easy-table-body-row-hover-font-color: #fff;

  --easy-table-body-row-background-color: #1e2b39;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 20px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}
</style>